import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Modal, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getID, getIsFilled } from "../../../utils/LocalStorage";
import { getAPI, postAPI } from "../../../utils/api";
import { useSnackbar } from "notistack";
import musicImage from "../../../assets/images/musicImage.png";
import Navbar from "../../commonComnents";
import ReactAudioPlayer from "react-audio-player";
import CommentComponent from "../../commonComnents/CommentBox";
import Anime from "react-anime";
import "./MusicPlayer.css";
import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";


const MusicPlay = () => {
  const navigates = useNavigate();
  const [isEnded, setIsEnded] = useState(false);
  const [ID, setID] = useState("");
  const [song, setSong] = useState({ id: "" });
  const [open, setOpen] = React.useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedEmoji, setselectedEmoji] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setselectedEmoji(false);
  };
  const { enqueueSnackbar } = useSnackbar();

  const onEnded = (e) => {
    setIsEnded(true);
  };

  useEffect(() => {
    const id = getID();
    const isFilled = getIsFilled();
    if (isFilled) {
      setID(id);
    } else {
      navigates("/questionare");
    }
  }, []);

  useEffect(() => {
    if (ID != "") {
      setTimeout(() => {
        getSong();
      }, 5000);
    }
  }, [ID]);

  const getSong = async () => {
    setIsLoading(true);
    const response = await getAPI(`getNextUserSong?id=${ID}`);
    console.log("=======get song response", response);
    if (response.data.success) {
      setIsLoading(false);
      setSong(response.data);
      setIsError(false);
    } else {
      setIsLoading(false);
      setSong({ url: "" });
      setIsError(true);
      setIsEnded(false);
    }
  };

  const submitRating = async (emoji) => {
    const db = firebase.firestore();
    const auth = getAuth();
    const user = auth.currentUser;
    const queryUserSongs = db.collection("user_songs");
    try {
      await queryUserSongs.where("user_id", "==", user.auth.currentUser.uid)
        .where("song_id", "==", song.id).limit(1).get().then((userSongs) => {
          const userSong = userSongs.docs[0];
          userSong.ref.update({"rating": emoji,
          "is_listened": true,
          "is_rated": true});
        });
      handleOpen();
    } catch (error) {
        enqueueSnackbar("Error in submitting rating", {
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          variant: "error",
        });
    }
  };

  const getNextSong = () => {
    setIsEnded(false);
    setIsLoading(true);
    setTimeout(() => {
      getSong();
    }, 2000);
    setselectedEmoji("");
    handleClose();
  };

  const Emoji = (props) => (
    <span
      className="emoji"
      role="img"
      aria-label={props.label ? props.label : ""}
      aria-hidden={props.label ? "false" : "true"}
    >
      {props.symbol}
    </span>
  );

  const rateSong = (emoji) => {
    submitRating(emoji);
    setselectedEmoji(emoji);
  };


  return (
    <div>
      <div className="musicPlay">
        <Navbar />
        <div>
          <img src={musicImage} alt="Music Album" className="musicImage" />
        </div>
        <div className="musicBottomContainer">
          {isLoading ? (
            <CircularProgress />
          ) : !isEnded && !isError && !isLoading ? (
            <div className="audioPlayerContainer">
              <div className="music_info">
                <h4 className="pointer">{song.name}</h4>
                {song.genre}
              </div>
              <ReactAudioPlayer
                onEnded={onEnded}
                src={song.url}
                controls
                className="audioStyle"
              />
            </div>
          ) : !isEnded && isError && !isLoading ? (
            <>
              <h3>There are no more songs in the list...</h3>
            </>
          ) : (
            <>
              <div className="mainEmoji">
                <div className="emojiContainer">
                  <div className="emojiStyle" onClick={() => rateSong("🔥")}>
                    {selectedEmoji == "🔥" ? (
                      <Anime
                        easing="easeOutElastic"
                        loop={true}
                        duration={2000}
                        delay={(el, index) => index * 240}
                        scale={[0.8, 1.5]}
                      >
                        <div className="blue" />
                        <div className="green" />
                        <div className="red" />

                        <div
                          className="emojiStyleSub"
                          onClick={() => rateSong("🔥")}
                        >
                          <Emoji className="emoji" symbol="🔥" />
                        </div>
                      </Anime>
                    ) : (
                      <Emoji className="emoji" symbol="🔥"></Emoji>
                    )}
                  </div>
                  <div className="emojiStyle" onClick={() => rateSong("😍")}>
                    {selectedEmoji == "😍" ? (
                      <Anime
                        easing="easeOutElastic"
                        loop={true}
                        duration={1000}
                        delay={(el, index) => index * 240}
                        scale={[0.8, 1.5]}
                      >
                        <div className="blue" />
                        <div className="green" />
                        <div className="red" />

                        <div
                          className="emojiStyleSub"
                          onClick={() => rateSong("😍")}
                        >
                          <Emoji className="emoji" symbol="😍" />
                        </div>
                      </Anime>
                    ) : (
                      <Emoji className="emoji" symbol="😍"></Emoji>
                    )}
                  </div>
                  <div className="emojiStyle" onClick={() => rateSong("👍")}>
                    {selectedEmoji == "👍" ? (
                      <Anime
                        easing="easeOutElastic"
                        loop={true}
                        duration={2000}
                        delay={(el, index) => index * 250}
                        scale={[0.8, 1.5]}
                      >
                        <div className="blue" />
                        <div className="green" />
                        <div className="red" />

                        <div
                          className="emojiStyleSub"
                          onClick={() => rateSong("👍")}
                        >
                          <Emoji className="emoji" symbol="👍" />
                        </div>
                      </Anime>
                    ) : (
                      <Emoji className="emoji" symbol="👍"></Emoji>
                    )}
                  </div>
                </div>
                <div className="emojiContainer">
                  <div className="emojiStyle" onClick={() => rateSong("👌")}>
                    {selectedEmoji == "👌" ? (
                      <Anime
                        easing="easeOutElastic"
                        loop={true}
                        duration={1000}
                        delay={(el, index) => index * 240}
                        scale={[0.8, 1.5]}
                      >
                        <div className="blue" />
                        <div className="green" />
                        <div className="red" />

                        <div
                          className="emojiStyleSub"
                          onClick={() => rateSong("👌")}
                        >
                          <Emoji className="emoji" symbol="👌" />
                        </div>
                      </Anime>
                    ) : (
                      <Emoji className="emoji" symbol="👌"></Emoji>
                    )}
                  </div>

                  <div className="emojiStyle" onClick={() => rateSong("😐")}>
                    {selectedEmoji == "😐" ? (
                      <Anime
                        easing="easeOutElastic"
                        loop={true}
                        duration={1000}
                        delay={(el, index) => index * 240}
                        scale={[0.8, 1.5]}
                      >
                        <div className="blue" />
                        <div className="green" />
                        <div className="red" />

                        <div
                          className="emojiStyleSub"
                          onClick={() => rateSong("😐")}
                        >
                          <Emoji className="emoji" symbol="😐" />
                        </div>
                      </Anime>
                    ) : (
                      <Emoji className="emoji" symbol="😐"></Emoji>
                    )}
                  </div>
                  <div className="emojiStyle" onClick={() => rateSong("💩")}>
                    {selectedEmoji == "💩" ? (
                      <Anime
                        easing="easeOutElastic"
                        loop={true}
                        duration={1000}
                        delay={(el, index) => index * 240}
                        scale={[0.8, 1.5]}
                      >
                        <div className="blue" />
                        <div className="green" />
                        <div className="red" />

                        <div
                          className="emojiStyleSub"
                          onClick={() => rateSong("💩")}
                        >
                          <Emoji className="emoji" symbol="💩" />
                        </div>
                      </Anime>
                    ) : (
                      <Emoji className="emoji" symbol="💩"></Emoji>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <CommentComponent songID={song.id} />
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalStyle">
          <div>
            <p className="nextTextStyling">
              Your response has been submitted, do you want to play next song?
            </p>
            <div className="buttonsContainer">
              <div className="noStyle" onClick={handleClose}>
                No
              </div>
              <div className="yesStyle" onClick={getNextSong}>
                Yes
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default MusicPlay;
