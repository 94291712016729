import React, { useState } from "react";
import { FormControl, InputLabel, NativeSelect } from "@mui/material";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { setIsFilled } from "../../../utils/LocalStorage";
import firebase from "firebase/compat/app";
import MusicButton from "../../commonComnents/Button";
import "./Questionare.css";

const Questionare = () => {
  const [userData, setUserData] = useState({
    fName: "",
    lName: "",
    gender: "",
    ethnicity: "",
    birth_date: "",
    email: "",
    phone: "",
    state: "",
    zipcode: "",
    attendingCollege: "",
    collegeName: "",
    genre: "Pop",
    musicHour: "",
    rank_genres: {},
  });
  const navigates = useNavigate();
  const [music, setmusic] = useState({
    RB: "",
    hip_hop: "",
    pop: "",
    rock: "",
    latin: "",
    country: "",
    EDM: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const phoneRegex =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  const [errorText, setErrorText] = useState("");

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
    inputValidations(name , value)
  };

  const handleOnChangeMusic = (event) => {
    const { name, value } = event.target;
    setmusic({ ...music, [name]: value });
  };

  const inputValidations = (name, value) => {
   if (name == "phone") {
      if (value.match(phoneRegex)) {
        setErrorText("");
      } else {
        setErrorText("Invalid format");
      }
    } 
  };

  const Submit = async () => {
    setIsLoading(true);
    const random = Math.floor(Math.random() * 899999 + 100000)
    const db = firebase.firestore();
    const auth = getAuth();
    const user = auth.currentUser;
    const data = {
      ...userData,
      rank_genres: music,
      user_id: user.auth.currentUser.uid,
    };
    const res = await db
      .collection("survey_response")
      .add(data)
      .then((doc) => {
        setIsLoading(false);
        setIsFilled(true);
        navigates(`/musicplay/${random}`);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <div className="mainContainerQuestion">
      <div className="headingStyle">Fill Out This Questionare</div>
      <div className="emailInput">
        <span className="lableStyle">First Name</span>
        <input
          className="questionInput"
          placeholder="Enter your first name"
          type="text"
          id="fName"
          name="fName"
          value={userData.fName}
          onChange={handleOnChange}
        />
      </div>
      <br />
      <div className="emailInput">
        <span className="lableStyle">Last Name</span>
        <input
          className="questionInput"
          placeholder="Enter your last name"
          type="text"
          id="lName"
          name="lName"
          value={userData.lName}
          onChange={handleOnChange}
        />
      </div>
      <br />
      <div className="emailInput">
        <span className="lableStyle">Gender</span>
        <div className="radioBoxGender">
          <input
            type="radio"
            id="gender"
            name="gender"
            value="male"
            onChange={handleOnChange}
          />
          <label className="genderLabel" for="male">
            Male
          </label>
        </div>
        <div className="radioBoxGender">
          <input
            type="radio"
            id="gender"
            name="gender"
            value="female"
            onChange={handleOnChange}
          />
          <label className="genderLabel" for="female">
            Female
          </label>{" "}
          <br></br>
        </div>
        <div className="radioBox">
          <input
            type="radio"
            id="gender"
            name="gender"
            value="other"
            onChange={handleOnChange}
          />
          <label className="genderLabel" for="other">
            Other
          </label>{" "}
          <br></br>
        </div>
      </div>
      <br />

      <div className="emailInput">
        <span className="lableStyle">Ethenicity</span>
        <FormControl>
          <NativeSelect
            className="dropdownStyle"
            defaultValue={30}
            name="ethnicity"
            value={userData.ethnicity}
            onChange={handleOnChange}
            inputProps={{
              name: "ethnicity",
              id: "ethnicity",
            }}
          >
            <option value="Asian">Asian</option>
            <option value="Black">Black</option>
            <option value="Hispanic">Hispanic</option>
            <option value="Native">Native</option>
            <option value="American">American</option>
            <option value="Native Hawaiian">Native Hawaiian</option>
            <option value="Other">Other</option>
            <option value="White">White</option>
          </NativeSelect>
        </FormControl>
      </div>
      <br />
      <div className="emailInput">
        <span className="lableStyle">Birthdate</span>
        <input
          className="datePickerStyle"
          placeholder="Enter your birth_date"
          type="date"
          id="birth_date"
          name="birth_date"
          value={userData.birth_date}
          onChange={handleOnChange}
        />
      </div>
      <br />
      <div className="emailInput">
        <span className="lableStyle">Email</span>
        <input
          className="questionInput"
          placeholder="Enter your email"
          type="text"
          id="email"
          name="email"
          value={userData.email}
          onChange={handleOnChange}
        />
      </div>
      <br />
      <div className="emailInput">
        <span className="lableStyle">Cell Phone#</span>
        <input
          className="questionInput"
          placeholder="Enter your Cell Phonenumber"
          type="text"
          required
          id="phone"
          name="phone"
          value={userData.phone}
          onChange={handleOnChange}
        />
        <div className="errorStyle"> {errorText}</div>

      </div>
      <br />

      <div className="emailInput">
        <span className="lableStyle">Choose state</span>
      </div>
      <div className="rangeItems">
        <FormControl>
          <NativeSelect
            defaultValue={30}
            className="dropdownStyle"
            name="state"
            value={userData.state}
            onChange={handleOnChange}
            inputProps={{
              name: "state",
              id: "state",
            }}
          >
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California.">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
          </NativeSelect>
        </FormControl>
      </div>
      <br />
      <div className="emailInput">
        <span className="lableStyle">Zipcode</span>
        <input
          className="questionInput"
          placeholder="Enter your zipcode"
          type="text"
          id="zipcode"
          name="zipcode"
          value={userData.zipcode}
          onChange={handleOnChange}
        />
      </div>
      <br />
      <div className="emailInput">
        <span className="lableStyleCollege">Are you attending college?</span>
        <br />
        <div className="radioBox">
          <input
            type="radio"
            id="attendingCollege"
            name="attendingCollege"
            value="yes"
            onChange={handleOnChange}
          />
          <label className="collegeLabel" for="male">
            Yes
          </label>{" "}
        </div>
        <div className="radioBox">
          <input
            type="radio"
            id="ateendingCollege"
            name="attendingCollege"
            value="no"
            onChange={handleOnChange}
          />
          <label className="collegeLabel" for="female">
            No
          </label>{" "}
          <br></br>
        </div>
      </div>
      <br></br>
      <div className="emailInput">
        <span className="lableStyleCollege">
          What College are you attending?
        </span>
        <br></br>
        <input
          className="questionInput"
          placeholder="What college are you attending"
          type="text"
          id="collegeName"
          name="collegeName"
          value={userData.collegeName}
          onChange={handleOnChange}
        />
      </div>
      <br />

      <div className="emailInput">
        <span className="mediaStyle">
          What is your favorite style of music?
        </span>
        <FormControl>
          <NativeSelect
            defaultValue={30}
            name="genre"
            value={userData.genre}
            onChange={handleOnChange}
            inputProps={{
              name: "genre",
              id: "genre",
            }}
            className="dropdownStyle"
          >
            <option value={"R&B"}>R&B</option>
            <option value={"country"}>Country</option>
            <option value={"hip hop"}>Hip Hop</option>
            <option value={"latin"}>Latin</option>
            <option value={"pop"}>Pop</option>
            <option value={"rock"}>Rock</option>
            <option value={"EDM"}>EDM</option>
          </NativeSelect>
        </FormControl>
      </div>
      <div className="range">
        <label className="inputRange" for="points">
          Rank these genres from one to eight (1= I don’t like it at all / 8 = I
          love it): :
        </label>
      </div>
      <br />

      <div className="rangeItems">
        <div className="textColor">R&B</div>
        <FormControl>
          <NativeSelect
            defaultValue={30}
            name="RB"
            id="points"
            value={music.RB}
            onChange={handleOnChangeMusic}
            inputProps={{
              name: "RB",
              id: "points",
            }}
            className="dropdownStyle"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
          </NativeSelect>
        </FormControl>
        <br />
        <div className="rangeItems">
          <div className="textColor">Hip Hop</div>
          <FormControl>
            <NativeSelect
              defaultValue={30}
              name="hip_hop"
              id="points"
              value={music.hip_hop}
              onChange={handleOnChangeMusic}
              inputProps={{
                name: "hip_hop",
                id: "points",
              }}
              className="dropdownStyle"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </NativeSelect>
          </FormControl>
        </div>{" "}
        <br />
        <div className="rangeItems">
          <div className="textColor">Pop</div>
          <FormControl>
            <NativeSelect
              defaultValue={30}
              name="pop"
              id="points"
              value={music.pop}
              onChange={handleOnChangeMusic}
              inputProps={{
                name: "pop",
                id: "points",
              }}
              className="dropdownStyle"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </NativeSelect>
          </FormControl>
        </div>{" "}
        <br />
        <div className="rangeItems">
          <div className="textColor">Rock</div>
          <FormControl>
            <NativeSelect
              defaultValue={30}
              name="rock"
              id="points"
              value={music.rock}
              onChange={handleOnChangeMusic}
              inputProps={{
                name: "rock",
                id: "points",
              }}
              className="dropdownStyle"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </NativeSelect>
          </FormControl>
        </div>{" "}
        <br />
        <div className="rangeItems">
          <div className="textColor">Latin</div>
          <FormControl>
            <NativeSelect
              defaultValue={30}
              name="latin"
              id="points"
              value={music.latin}
              onChange={handleOnChangeMusic}
              inputProps={{
                name: "latin",
                id: "points",
              }}
              className="dropdownStyle"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </NativeSelect>
          </FormControl>
        </div>{" "}
        <br />
        <div className="rangeItems">
          <div className="textColor">Countrty</div>
          <FormControl>
            <NativeSelect
              defaultValue={30}
              name="country"
              id="points"
              value={music.country}
              onChange={handleOnChangeMusic}
              inputProps={{
                name: "country",
                id: "points",
              }}
              className="dropdownStyle"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </NativeSelect>
          </FormControl>
        </div>{" "}
        <br />
        <div className="rangeItems">
          <div className="textColor">EDM</div>
          <FormControl>
            <NativeSelect
              defaultValue={30}
              name="EDM"
              id="points"
              value={music.EDM}
              onChange={handleOnChangeMusic}
              inputProps={{
                name: "EDM",
                id: "points",
              }}
              className="dropdownStyle"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </NativeSelect>
          </FormControl>
        </div>
      </div>
      <br></br>
      <div className="emailInput">
        <span className="lableStyleCollege">
          How many hours per week do you listen to music?
        </span>
        <br></br>
        <div className="radioBox">
          <input type="radio" id="musicHour" name="musicHour" value="0-1" />
          <label className="collegeLabel" for="male">
            0-1
          </label>{" "}
        </div>
        <div className="radioBox">
          <input
            type="radio"
            id="musicHour"
            name="musicHour"
            value="1-2"
            onChange={handleOnChange}
          />
          <label className="collegeLabel" for="female">
            1-2
          </label>{" "}
          <br></br>
        </div>
        <div className="radioBox">
          <input
            type="radio"
            id="musicHour"
            name="musicHour"
            value="2-4"
            onChange={handleOnChange}
          />
          <label className="collegeLabel" for="female">
            2-4
          </label>{" "}
          <br></br>
        </div>{" "}
        <div className="radioBox">
          <input
            type="radio"
            id="musicHour"
            name="musicHour"
            value="4-6"
            onChange={handleOnChange}
          />
          <label className="collegeLabel" for="female">
            4-6
          </label>{" "}
          <br></br>
        </div>{" "}
        <div className="radioBox">
          <input
            type="radio"
            id="musicHour"
            name="musicHour"
            value="6-8"
            onChange={handleOnChange}
          />
          <label className="collegeLabel" for="female">
            6-8
          </label>{" "}
          <br></br>
        </div>
        <div className="radioBox">
          <input
            type="radio"
            id="musicHour"
            name="musicHour"
            value="8-10"
            onChange={handleOnChange}
          />
          <label className="collegeLabel" for="female">
            8-10
          </label>{" "}
          <br></br>
        </div>
        <div className="radioBox">
          <input
            type="radio"
            id="musicHour"
            name="musicHour"
            value="10+"
            onChange={handleOnChange}
          />
          <label className="collegeLabel" for="female">
            10+
          </label>{" "}
          <br></br>
        </div>
      </div>
      <br></br>
      <br />
      <div className="questionButton">
        <MusicButton title="Submit" isLoading={isLoading} onClick={Submit} />
      </div>
    </div>
  );
};
export default Questionare;
